"use client";

import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import mixpanel from "mixpanel-browser";
import { UIContextProvider } from "./contexts/ui";
import { store } from "./redux/store";
import ApolloWrapper from "../apollo/wrapper";
import AppProvider from "./components/AppProvider/AppProvider";
import { SparkContextProvider } from "./contexts/spark";

const Providers = ({ children }) => {
  // TODO: add property for staging/production
  // mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN);

  return (
    <ApolloWrapper>
      <Provider store={store}>
        <GoogleOAuthProvider
          clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string}
        >
          <UIContextProvider>
            <AppProvider>
              <SparkContextProvider>{children}</SparkContextProvider>
            </AppProvider>
          </UIContextProvider>
        </GoogleOAuthProvider>
      </Provider>
    </ApolloWrapper>
  );
};

export default Providers;
