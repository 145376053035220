/* eslint-disable no-alert */
import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { print } from "graphql";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setIsAppSession,
  setAppUser,
  setFcmToken,
} from "../../redux/slices/appSlice";
import MobileLoadingSpinner from "../MobileLoadingSpinner/MobileLoadingSpinner";
import { CURRENT_USER_QUERY } from "../../../apollo/queries";
import { AnalyticsEventName } from "../../../types";
import { track } from "../../utils";

const AppProvider = ({ children }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { appUser, isAppSession } = useAppSelector((state) => state.app);

  const getCookie = async (token) => {
    try {
      // fetch user data from api
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // set authorization header to the cookie value
            authorization: `Bearer ${token}`,
          },
          credentials: "include",
          body: JSON.stringify({
            query: print(CURRENT_USER_QUERY),
          }),
          cache: "no-store",
        },
      );

      const {
        data: { currentUser: user },
      } = await response.json();

      if (user && !isAppSession && !appUser) {
        const prevLocation = window.localStorage.getItem("prevLocation");

        dispatch(setIsAppSession(true));
        dispatch(setAppUser(user));

        if (prevLocation) {
          router.push(prevLocation);
        } else {
          router.push("/sparks");
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(`getCookie() fetch error: ${JSON.stringify(err, null, 2)}`);
    }
  };

  const handleAppMessages = async (event) => {
    const prevLocation = window.localStorage.getItem("prevLocation");

    if (typeof event.data === "string" && event.data !== "bcEmpty") {
      const data = JSON.parse(event.data);
      // check for and handle login event

      // mobileLogin action is sent from the native app when the home screen is loaded
      if (!appUser && data.action === "mobileLogin" && data.userData) {
        // call the server with the native token to get it stored as cookies on webview browser
        // track mobile signups in mixpanel
        if (data.userData.newUser) {
          track(AnalyticsEventName.MOBILE_SIGNUP);
        }
        setIsLoggingIn(true);
        await getCookie(data.userData.token);
        dispatch(setFcmToken(data.userData.fcmToken));
        track(AnalyticsEventName.MOBILE_LOGIN);

        if (prevLocation) router.push(prevLocation);
        else router.push("/sparks");
        setIsLoggingIn(false);
      }

      // handle notification redirections
      if (appUser && data.action === "redirect" && data.destination) {
        router.push(data.destination);
      }
    }
  };

  useEffect(() => {
    window.onmessage = handleAppMessages;

    return () => {
      window.onmessage = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggingIn) return <MobileLoadingSpinner />;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default AppProvider;
