/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Cluster, Spark } from "../../../types";

export interface SearchState {
  metaOnly: boolean;
  query: string;
  context: string;
  type: string[];
  collaboration: boolean;
  sparks: {
    loading: boolean;
    searched: boolean;
    results: Spark[];
    total: number;
    totalPages: number;
    page: number;
  };
  clusters: {
    loading: boolean;
    searched: boolean;
    results: Cluster[];
    total: number;
    totalPages: number;
    page: number;
  };
}

const initialState: SearchState = {
  metaOnly: false,
  query: "",
  context: "",
  type: [],
  collaboration: false,
  sparks: {
    loading: false,
    searched: false,
    results: [],
    total: 0,
    totalPages: 0,
    page: 0,
  },
  clusters: {
    loading: false,
    searched: false,
    results: [],
    total: 0,
    totalPages: 0,
    page: 0,
  },
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setMetaOnly: (state, action: PayloadAction<boolean>) => {
      state.metaOnly = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    setContext: (state, action: PayloadAction<string>) => {
      state.context = action.payload;
    },
    setType: (state, action: PayloadAction<string[]>) => {
      state.type = action.payload;
    },
    setCollaboration: (state, action: PayloadAction<boolean>) => {
      state.collaboration = action.payload;
    },
    setSparksLoading: (state, action: PayloadAction<boolean>) => {
      state.sparks.loading = action.payload;
    },
    setClustersLoading: (state, action: PayloadAction<boolean>) => {
      state.clusters.loading = action.payload;
    },
    setSparksSearched: (state, action: PayloadAction<boolean>) => {
      state.sparks.searched = action.payload;
    },
    setClustersSearched: (state, action: PayloadAction<boolean>) => {
      state.clusters.searched = action.payload;
    },
    setSparksResults: (state, action: PayloadAction<Spark[]>) => {
      state.sparks.results = action.payload;
    },
    setClustersResults: (state, action: PayloadAction<Cluster[]>) => {
      state.clusters.results = action.payload;
    },
    setResetResults: (state) => {
      state.sparks.results = [];
      state.sparks.total = 0;
      state.sparks.totalPages = 0;
      state.sparks.page = 0;
      state.sparks.searched = false;

      state.clusters.results = [];
      state.clusters.total = 0;
      state.clusters.totalPages = 0;
      state.clusters.page = 0;
      state.clusters.searched = false;
    },
    setClearSearch: (state) => {
      state.query = "";
      state.context = "";
      state.type = [];
      state.metaOnly = false;
      state.collaboration = false;

      state.sparks.results = [];
      state.sparks.total = 0;
      state.sparks.totalPages = 0;
      state.sparks.page = 0;
      state.sparks.searched = false;

      state.clusters.results = [];
      state.clusters.total = 0;
      state.clusters.totalPages = 0;
      state.clusters.page = 0;
      state.clusters.searched = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setMetaOnly,
  setQuery,
  setContext,
  setType,
  setCollaboration,
  setSparksLoading,
  setClustersLoading,
  setSparksSearched,
  setClustersSearched,
  setSparksResults,
  setClustersResults,
  setResetResults,
  setClearSearch,
} = searchSlice.actions;

export default searchSlice.reducer;
