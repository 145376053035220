/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "@/app/types";
import { User } from "../../../types";

const initialState: AppState = {
  isAppSession: false,
  appUser: null,
  fcmToken: null,
  eventListenerIsSet: false,
  isLoggingOut: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsAppSession: (state, action: PayloadAction<boolean>) => {
      state.isAppSession = action.payload;
    },
    setAppUser: (state, action: PayloadAction<User>) => {
      state.appUser = action.payload;
    },
    setFcmToken: (state, action: PayloadAction<string>) => {
      state.fcmToken = action.payload;
    },
    setIsLoggingOut: (state, action: PayloadAction<boolean>) => {
      state.isLoggingOut = action.payload;
    },
    nativeLogout: () => {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ userLoggedOut: true }),
      );
      window.localStorage.removeItem("prevLocation");
    },
  },
});

export const {
  setIsAppSession,
  setAppUser,
  setFcmToken,
  setIsLoggingOut,
  nativeLogout,
} = appSlice.actions;
export default appSlice.reducer;
